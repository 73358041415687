import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const StyledUserMessage = styled.p`
  background-color: rgba(255, 0, 0, 0.4);
  padding: 1em;
  border-radius: 1em;
`;

const ResultContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ResultField = ({ imgSrc }) => {
  imgSrc = imgSrc || "/labo/cow.jpg";
  return (
    <>
      <ResultImgWrapper>
        <ResultImg src={imgSrc} />
      </ResultImgWrapper>
    </>
  );
};

const ResultImgWrapper = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  width: 18em;
`;

const ResultImg = styled.img`
  width: 100%;
  border-radius: 1em;
  margin-top: 1em;
  /* display: flexbox; */
`;

const CheeseGenButton = styled.button`
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: white; //${({ theme }) => theme.maincolor.funcky_cheese};
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: grey; //${({ theme }) => theme.maincolor.funcky_cheese};;
    border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: ${({ theme }) => theme.maincolor.funcky_cheese};
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
`;

function buildPairs(a, b) {
  const c = a.map(function (e, i) {
    return [e, b[i]];
  });
  return c;
}

const PairContainer = styled.div`
  width: 10em;
  aspect-ratio: 2/1;
  display: flex;
  justify-content: center;
  margin: 0 0 1em 0;
`;

const PairWrapper = styled.div`
  gap: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const CheeseImage = styled(GatsbyImage)`
  width: 50%;
  aspect-ratio: auto;
`;
const RightCheeseImage = styled(CheeseImage)`
  border-radius: 0 1em 1em 0;
`;

const LeftCheeseImage = styled(CheeseImage)`
  border-radius: 1em 0 0 1em;
`;
const CheeseExamples = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      generated_img: allFile(
        filter: {
          sourceInstanceName: { eq: "assets" }
          relativeDirectory: { eq: "labo/cheeses_example/generated" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      real_img: allFile(
        filter: {
          sourceInstanceName: { eq: "assets" }
          relativeDirectory: { eq: "labo/cheeses_example/real" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  function convertToImage(node, position) {
    const image = getImage(node);
    if (position === "right") {
      return <RightCheeseImage image={image} key={node.name} />;
    }
    return <LeftCheeseImage image={image} key={node.name} />;
  }

  const realImages = data.real_img.edges.map((edge) =>
    convertToImage(edge.node, "left")
  );
  const genImages = data.generated_img.edges.map((edge) =>
    convertToImage(edge.node, "right")
  );
  var imagePairs = buildPairs(realImages, genImages);

  imagePairs = imagePairs.map((pair, index) => (
    <PairContainer key={index}>{pair}</PairContainer>
  ));
  return <PairWrapper>{imagePairs}</PairWrapper>;
};

const CheeseButtonWrapper = styled.div`
  padding-top: 50px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
`;
export {
  ResultContainer,
  ResultField,
  StyledUserMessage,
  CheeseGenButton,
  CheeseButtonWrapper,
  CheeseExamples,
};
