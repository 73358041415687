import { API, Storage } from "aws-amplify";
import React, { useContext, useRef, useState } from "react";
import { UserCreditContext } from "../../components/credits/credits_context";
import { SideBarCreditComponent } from "../../components/credits/credits_msg";
import { getDoubleToken } from "../../components/labo/token";
import Layout from "../../components/layout";
import LoadingAnimation from "../../components/loading";
import {
  ResultContainer,
  ResultField,
  CheeseGenButton,
  CheeseExamples,
} from "../../components/pages/cheese_gen_expe";
import PageHeading from "../../components/page_heading";
import SideBar from "../../components/sidebar";
import { generateCheese } from "../../graphql/queries";
import { Centerer } from "../../components/utils";
import { DynamicMessage } from "../../components/user_message";
import { Instruction } from "../../components/user_message";
import { useMsgState } from "../../components/user_message";

const CheeseExpePage = () => {
  const msgRef = useRef(null);
  const [userMsg, setUserMsg] = useMsgState("", msgRef);
  const [resultImgURL, setResultImgURL] = useState(undefined);
  const [isWaiting, setIsWaiting] = useState(false);
  const {
    userInfo,
    isAuthenticated,
    isReadyToRetry,
    hasSufficientCredits,
    setCreditsFromAPI,
  } = useContext(UserCreditContext);

  async function computeCheese() {
    setIsWaiting(true);
    try {
      const doubleToken = await getDoubleToken();
      const response = await API.graphql({
        query: generateCheese,
        authToken: doubleToken,
        authMode: "AWS_LAMBDA",
      });
      const status_code = response.data.generateCheese.status.status_code;
      if (status_code !== 200) {
        throw new Error("Non 200 status received from API");
      }

      // Update Credits
      let creditInfo = response.data.generateCheese.credit_info;
      let credits = creditInfo.credits;
      const endDate = new Date(creditInfo.end_date);
      setCreditsFromAPI(credits, endDate);

      const { bucket, key, region } = response.data.generateCheese;
      let imgUrl = await Storage.get(key.split("/").slice(-1)[0], {
        bucket,
        level: "private",
        region: region,
      });
      setResultImgURL(imgUrl);
    } catch (err) {
      setUserMsg("error");
    } finally {
      setIsWaiting(false);
    }
  }

  async function handleCheeseGeneration() {
    if (!isAuthenticated()) {
      setUserMsg("unauthenticated");
      return;
    }
    if (!hasSufficientCredits() && !isReadyToRetry()) {
      setUserMsg("no_credits");
      return;
    }
    await computeCheese();
  }

  const creditInfo = (
    <SideBarCreditComponent
      user={userInfo.user}
      credits={userInfo.credits}
      endDate={userInfo.endDate}
    />
  );
  const instruction = (
    <Instruction>
      To generate a cheese, simply click on the <i>Generate a cheese!</i>{" "}
      button: it should take ~ 40 seconds to obtain a result. You can then
      download your cheese by right clicking on the generated image -->{" "}
      <i> "Save image as ..."</i>
      <br />
      <br />
      Some generated images might seem a bit funny-looking: it is because the
      model does not model perfectly the distribution of cheese images. Here is
      a few hand-picked pairs of images with a real picture on the left and a
      generated image for the corresponding type of cheese on the right. These
      might help you to understand which type of cheese you got :)
    </Instruction>
  );

  return (
    <div>
      <SideBar elements={creditInfo} />
      <Layout iconName="labo">
        <PageHeading
          headingText="Cheese Generation"
          subHeadingText={`Generate your cheese!`}
        />

        <DynamicMessage refProp={msgRef} msgState={userMsg} />
        {instruction}
        <CheeseExamples />
        <Centerer>
          <CheeseGenButton onClick={handleCheeseGeneration}>
            Generate a cheese!
          </CheeseGenButton>
        </Centerer>
        <ResultContainer>
          <ResultField imgSrc={resultImgURL} />
        </ResultContainer>
        <p>
          And for the technical aspects it's{" "}
          <a href="/blog/cheese_gen_expe">there! </a>{" "}
        </p>
        {isWaiting && <LoadingAnimation nSeconds={40} nSteps={15} />}
      </Layout>
    </div>
  );
};

export default CheeseExpePage;
